/* @tailwind base; */
@tailwind components;
@tailwind utilities;

@import '~antd/dist/antd.css';

html {
    height: 100%;
    font-size: 62.5%;
}

#root {
    height: 100%;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
label {
    margin: 0;
}